import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "gatsby";

const UseStyles = makeStyles(styles);
const UseImgStyles = makeStyles(imgStyles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex' }}>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <Link to="/jereo-2019">
              <img src={'/generalSlides/jereo-2019.jpg'} alt={'plakatjereo 2019'} className={imgClass.img} />
            </Link>
            <Link to="/dzien-jednosci-2019">
              <img src={'/generalSlides/dzien-jednosci-2019.png'} alt={'plakatjereo 2019'} className={imgClass.img} />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
